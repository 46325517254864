{
  "name": "probe-web",
  "version": "0.1.0",
  "author": "Arturo Filastò <arturo@filasto.net>",
  "license": "BSD-3-Clause",
  "scripts": {
    "start": "parcel index.html",
    "dev": "parcel serve index.html",
    "build": "parcel build index.html",
    "lint": "prettier --write src/",
    "extract-strings": "formatjs extract 'src/**/*.{ts*,js*}'",
    "update-strings": "node scripts/update-strings.js",
    "analyze": "parcel index.html --reporter @parcel/reporter-bundle-analyzer",
    "deploy": "yarn run build && echo probe-web.ooni.org > dist/CNAME && gh-pages -d dist"
  },
  "devDependencies": {
    "@babel/core": "^7.16.5",
    "@babel/preset-react": "^7.17.12",
    "@formatjs/cli": "^6.0.4",
    "@parcel/reporter-bundle-analyzer": "^2.6.0",
    "@parcel/transformer-svg-react": "^2.5.0",
    "axios": "^1.3.4",
    "buffer": "^6.0.3",
    "csv-parse": "^5.3.6",
    "gh-pages": "^3.2.3",
    "parcel": "2.6.0",
    "prettier": "^2.6.2",
    "process": "^0.11.10",
    "typescript": "^4.2.4"
  },
  "dependencies": {
    "@fontsource/fira-sans": "^4.5.10",
    "@rebass/forms": "^4.0.6",
    "markdown-to-jsx": "^7.1.9",
    "minimist": "^1.2.3",
    "ooni-components": "^0.5.0-alpha.5",
    "prop-types": "^15.8.1",
    "rc-progress": "^3.2.1",
    "rc-util": "^5.16.1",
    "react": "^16.0.0 || ^17.0.0",
    "react-dom": "^17.0.2",
    "react-hook-form": "^7.31.1",
    "react-icon-base": "^2.1.2",
    "react-icons": "^4.4.0",
    "react-intl": "^5.24.0",
    "react-lottie-player": "^1.4.1",
    "react-router-dom": "6",
    "rehype-react": "^7.0.3",
    "remark-parse": "^10.0.1",
    "remark-rehype": "^10.1.0",
    "styled-components": "^5.3.3",
    "unified": "^10.1.1",
    "whatwg-fetch": "^3.6.2",
    "ws": "^7.4.6"
  },
  "resolutions": {
    "ansi-regex": "^5.0.1",
    "postcss": "^7.0.36",
    "json-schema": "^0.4.0",
    "minimist": "^1.2.3",
    "path-parse": "^1.0.7",
    "ws": "^7.4.6"
  }
}
