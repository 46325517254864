import React from "react";
import Icon from "react-icon-base";

const OONILogo = (props) => (
  <Icon viewBox="0 0 698.19 196.824" {...props}>
    <g>
      <g id="Logo">
        <g>
          <g>
            <g id="Page-1_1_">
              <g id="Wordmark-Horizontal_1_">
                <g
                  id="Logo-wordmark-HORIZ_1_"
                  transform="translate(1.000000, 1.000000)"
                >
                  <g
                    id="Wordmark_1_"
                    transform="translate(206.000000, 68.000000)"
                  >
                    <g id="Fill-10_1_">
                      <g>
                        <path
                          fill="#FFFFFF"
                          d="M41.751,34.262c0-16.843,5.511-30.388,16.536-40.643c11.078-10.254,25.75-15.38,44.02-15.38
                      c7.456,0,14.405,1.008,20.851,3.028c6.496,1.965,12.196,4.834,17.096,8.606c7.136,5.473,12.594,12.061,16.378,19.765
                      c3.781,7.704,5.67,16.071,5.67,25.102c0,17.001-5.725,30.949-17.176,41.839c-11.395,10.84-26.123,16.257-44.176,16.257
                      c-17.844,0-32.168-5.365-42.982-16.096C47.156,66.008,41.751,51.849,41.751,34.262L41.751,34.262z M102.146-9.489
                      c-8.521,0-15.312,4.143-20.37,12.433c-5.008,8.287-7.511,19.524-7.511,33.708c0,13.337,2.61,24.122,7.831,32.356
                      c5.272,8.234,12.01,12.352,20.211,12.352s14.806-4.196,19.811-12.591c5.008-8.395,7.511-19.684,7.511-33.869
                      c0-13.496-2.531-24.252-7.589-32.276C117.032-5.451,110.402-9.489,102.146-9.489L102.146-9.489z M174.573,34.262
                      c0-16.843,5.514-30.388,16.536-40.643c11.078-10.254,25.753-15.38,44.02-15.38c7.456,0,14.405,1.008,20.851,3.028
                      c6.496,1.965,12.196,4.834,17.096,8.606c7.136,5.473,12.597,12.061,16.378,19.765c3.781,7.704,5.672,16.071,5.672,25.102
                      c0,17.001-5.725,30.949-17.176,41.839c-11.398,10.84-26.123,16.257-44.178,16.257c-17.841,0-32.168-5.365-42.979-16.096
                      C179.979,66.008,174.573,51.849,174.573,34.262L174.573,34.262z M234.97-9.489c-8.521,0-15.312,4.143-20.373,12.433
                      c-5.008,8.287-7.509,19.524-7.509,33.708c0,13.337,2.61,24.122,7.829,32.356c5.272,8.234,12.01,12.352,20.211,12.352
                      s14.806-4.196,19.813-12.591c5.005-8.395,7.509-19.684,7.509-33.869c0-13.496-2.529-24.252-7.589-32.276
                      C249.854-5.451,243.225-9.489,234.97-9.489L234.97-9.489z M304.761,90.047v-9.164l9.985-0.957l3.116-3.108V-6.938
                      l-2.156-2.392l-10.944-1.194v-8.928h46.176v4.385l46.894,72.599V-6.222l-2.877-3.108l-10.705-1.194v-8.928h41.463v8.928
                      l-9.667,1.194l-3.116,3.108v96.269h-26.043L332.723,6.849v69.968l2.315,3.108l10.944,0.957v9.164H304.761z M432.79,90.047
                      v-9.483l11.824-0.638l2.557-3.108V-6.938l-2.557-2.392L432.79-9.966v-9.485h58.4v9.485L479.124-9.33l-2.635,2.392v83.756
                      l2.635,3.108l12.065,0.638v9.483H432.79z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            fill="#FFFFFF"
            d="M98.41,0.002C44.061,0.002,0,44.062,0,98.412c0,54.35,44.061,98.41,98.41,98.41
        c54.35,0,98.41-44.06,98.41-98.41C196.82,44.062,152.76,0.002,98.41,0.002z M165.184,104.792
        c-2.713,6.116-6.086,11.23-14.115,14.367c-5.854,2.287-7.962,2.46-9.195,2.847c-1.287,0.403-2.377,1.034-3.191,1.063
        c-1.992,0.07-10.268-1.46-12.141-2.1c-1.872-0.64-1.12-1.5-2.17-1.682c-1.05-0.183-2.428,0.128-3.531-0.414
        c-1.247-0.612-2.859-1.57-4.124-3.144c-0.982-1.223-4.338-5.053-6.687-6.283c-0.51,2.504,0.507,5.234,1.466,6.85
        c1.263,2.127,2.501,3.909,5.766,6.251c5.7,4.09,13.255,10.264,17.282,15.044c3.261,3.872,6.511,9.164,4.796,16.899
        c-1.391,6.274-8.464,10.781-12.565,12.417c-3.282,1.309-12.796,1.757-14.349,1.802c-1.552,0.045-2.842-1.528-3.846-1.847
        c-1.005-0.32-4.611,0.912-6.393,0.206c-1.435-0.568-3.255-2.545-4.825-4.076c-0.83-0.809-1.705-1.319-2.162-1.812
        c-0.377-0.404-0.613-1.318-0.907-1.986c-0.717-1.629-1.296-3.47-1.6-5.063c-0.273-1.431-0.469-2.897-0.456-4.095
        c0.008-0.806,0.44-1.625,0.549-2.672c0.109-1.037-0.208-2.778,0.054-3.588c0.687-2.129,1.742-3.472,3.579-5.172
        c2.234-2.065,4.667-2.329,7.137-2.244c2.326,0.081,4.042-0.36,4.952-0.371c1.66-0.021,2.863,0.823,3.518,1.332
        c1.233,0.958,1.529,2.055,1.347,2.968c-0.183,0.913-1.24,1.505-2.352,1.37c-1.42-0.173-3.678-0.996-5.981,0.731
        c-2.142,1.606-1.164,7.693,1.347,10.936c2.521,3.253,6.638,5.017,10.936,5.114c4.018,0.092,6.934-2.113,8.218-3.813
        c1.484-1.963,2.718-7.123,0.023-10.889c-2.513-3.516-8.379-7.913-10.936-9.511c-2.557-1.598-8.014-4.417-12.008-9.052
        c-1.573-1.824-3.703-4.759-5.883-8.092c-1.593,1.319-3.086,3.98-3.868,4.96c-0.912,1.147-1.877,2.063-2.841,2.87
        c-1.689,1.417-3.915,2.455-5.559,3.322c-1.643,0.867-3.159,2.183-5.32,3.218c-2.191,1.05-3.315,0.947-6.529,2.123
        c-3.599,1.318-6.253,3.854-6.598,7.581c-0.399,4.338,4.31,7.343,5.753,7.899c0.933,0.359,6.129,0.225,7.316-0.062
        c0.532-0.128,1.145-0.159,1.671-0.122c1.444,0.104,2.639,3.852,3.638,4.408c0.626,0.348,1.083,0.062,1.957,0.389
        c0.675,0.253,1.413,1.23,1.559,1.369c0.879,0.833,0.331,1.643,1.382,3.185c1.269,1.861,0.902,5.103,0.856,6.519
        c-0.045,1.415-0.958,4.04-1.187,4.816c-0.228,0.776-0.274,2.237-0.503,3.104c-0.228,0.867-2.121,2.331-2.716,2.763
        c-0.61,0.443-1.278,0.389-1.735,0.48c-0.457,0.092-1.683,2.552-4.498,3.539c-3.238,1.135-4.476,1.37-7.967,1.392
        c-3.197,0.021-7.364-3.501-7.032-3.767c0.188-0.149,4.594-0.516,7.809-1.918c1.872-0.815,4.629-3.069,4.999-7.328
        c0.365-4.201-3.669-6.208-5.976-7.393c-3.352-1.722-6.393-3.482-9.868-5.483c-4.2-2.42-6.707-5.732-8.082-8.835
        c-1.506-3.402-1.781-6.232-0.411-11.164c1.37-4.932,5.264-7.936,9.679-11.028c4.774-3.341,11.735-7.099,16.53-9.656
        c3.358-1.791,5.455-3.671,5.913-7.03c0.067-0.49,0.036-2.376-0.067-2.964c-0.413,0.407-2.623,2.767-3.465,3.625
        c-3.312,3.372-8.759,7.367-16.411,8.625c-1.964,0.324-1.535,1.251-3.645,1.478c-0.833,0.089-3.345,0.012-4.524-0.024
        c-3.423-0.106-5.618-1.131-8.782-2.228c-3.164-1.099-7.433-2.496-12.721-7.33c-5.141-4.699-6.715-9.008-7.62-15.4
        c-0.905-6.393,0.867-10.909,3.035-14.723c2.991-5.261,7.232-8.523,9.622-11.106c2.39-2.583,4.594-5.554,6.328-5.682
        c1.744-0.13,2.844,1.915,4.133,2.065c0.923,0.109,1.815-0.606,3-0.848c0.826-0.168,1.729,0.021,2.198,0.139
        c1.033,0.258,1.356,2.292,2.13,3.26c0.904,1.131,3.1,0.323,3.939,0.969c0.34,0.262,0.982,0.742,1.216,1.264
        c0.343,0.765,0.429,1.697,0.544,2.426c0.194,1.227,2.985,3.51,3.374,4.608c0.387,1.098-1.585,9.656-3.423,12.011
        c-2.054,2.632-5.682,4.552-6.135,4.746c-0.452,0.194-0.872-0.129-0.872-0.646s1.782-4.598,1.744-7.232
        c-0.065-4.424-2.923-6.851-4.856-7.892c-3.604-1.94-6.881-1.493-11.319,3.694c-3.654,4.27-5.671,10.919-1.775,16.401
        c2.031,2.859,4.74,7.833,14.756,7.749c11.622-0.098,18.692-3.405,21.76-9.524c2.834-5.655,1.272-11.928-5.642-18.678
        c-6.914-6.75-10.714-15.777-10.722-25.192c0-19.619,16.136-35.523,36.041-35.523s36.557,15.904,36.557,35.523
        c-0.014,9.248-4.202,18.125-9.816,23.93c-5.615,5.804-6.719,13.182-4.315,19.488c5.226,13.71,18.275,14.904,23.472,13.446
        c5.197-1.458,8.235-5.17,9.35-7.972c1.115-2.801,1.528-7.435,0.295-9.215c-1.232-1.781-2.017-2.541-2.29-3.409
        c-0.291-0.918,0.176-2.318-0.252-3.052c-0.771-1.318-3.757-3.203-4.843-4.433c-1.084-1.23-2.526-2.739-3.597-5.16
        c-0.959-2.17-1.271-2.869-2.048-4.057c-0.776-1.187-5.337-5.299-5.245-7.878c0.065-1.831,1.104-1.021,1.605-2.817
        c0.483-1.732-0.09-5.282,0.209-6.387c0.409-1.501,2.327-1.435,2.965-2.805c0.64-1.37,0.05-3.434,0.576-4.431
        c0.981-1.863,4.563-4.851,5.066-5.901c0.502-1.051,0.23-1.178,0.583-1.687c0.202-0.29,1.144-1.424,1.681-1.536
        c0.491-0.103,4.037,0.112,4.846-0.159c0.746-0.25,1.71-1.387,3.521-1.452c0.12-0.005,0.245-0.004,0.373,0.002
        c2.055,0.091,2.905,0.38,3.688,0.751c0.645,0.307,3.23,1.797,2.362,3.27c-1.086,1.84-8.73,4.912-10.516,14.925
        c-1.786,10.014,2.219,15.001,5.416,19.887c3.196,4.886,6.302,9.596,7.885,13.145C166.06,90.866,167.897,98.676,165.184,104.792z"
          />
          <path
            fill="#FFFFFF"
            d="M91.531,36.141c-9.749,0-17.653,7.903-17.653,17.654c0,9.75,7.904,17.653,17.653,17.653
        c9.75,0,17.654-7.903,17.654-17.653C109.185,44.045,101.281,36.141,91.531,36.141z M89.243,60.36
        c-2.232,0-4.041-1.809-4.041-4.041s1.809-4.041,4.041-4.041s4.041,1.809,4.041,4.041S91.475,60.36,89.243,60.36z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
export default OONILogo;
